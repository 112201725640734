<script setup lang="ts">
const { notifications, removeOne } = useNotifications();
</script>
<template>
  <div
    data-testid="notification-container"
    class="sm:top-30 z-305 fixed bottom-10 right-5 max-h-fit"
  >
    <LayoutNotification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      @click:close="removeOne(notification.id)"
    />
  </div>
</template>
